import React from 'react'
import tw from 'twin.macro'

export const HTMLContent = ({ content }) => (
  <div
    tw="prose prose-sm sm:(prose)"
    dangerouslySetInnerHTML={{ __html: content }}
  />
)

const Content = ({ content }) => <div>{content}</div>

export default Content
