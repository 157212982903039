import React from 'react'
import tw from 'twin.macro'
import { graphql } from 'gatsby'

import Layout from '../components/Layout'
import { HTMLContent } from '../components/Content'
import { H1 } from '../components/core/typography'
import { Container } from '../components/core/container'
import SEO from '../components/SEO'

import { getAnchor } from '../utils'

const ContentWrapper = tw(Container)`
  my-24
  last:(mb-0)
  max-w-screen-lg
`

const IndexCard = tw.div`
  col-span-3
  md:(col-span-2)
  p-4
  h-full
  bg-ui-03
  rounded
`

const IndexTitle = tw.span`
  text-xl
  font-semibold
  leading-normal
`

export const ServicePageTemplate = ({ services, title, heading }) => (
  <div>
    <SEO title={title} description={heading}></SEO>
    <ContentWrapper tw="flex justify-center text-center">
      <H1>{heading}</H1>
    </ContentWrapper>
    <div tw="pb-24 relative before:(content absolute bg-ui-01 inset-x-0 top-16 bottom-0 -z-10)">
      <ContentWrapper>
        <div tw="grid grid-cols-6 gap-4">
          {services &&
            services.map(
              (service) =>
                service.frontmatter.indexed && (
                  <IndexCard as="a" href={`#${getAnchor(service.slug)}`}>
                    <IndexTitle>{service.frontmatter.title}</IndexTitle>
                    <p>{service.frontmatter.tagline}</p>
                  </IndexCard>
                )
            )}
        </div>
      </ContentWrapper>
      {services &&
        services.map((service) => (
          <ContentWrapper tw="max-w-screen-md" id={getAnchor(service.slug)}>
            <article>
              <HTMLContent content={service.html} />
            </article>
          </ContentWrapper>
        ))}
    </div>
  </div>
)

const ServicePage = ({ data }) => {
  const { frontmatter } = data.markdownRemark
  const services = data.services.edges.map((edge) => ({
    html: edge.node.html,
    frontmatter: edge.node.frontmatter,
    slug: edge.node.fields.slug
  }))

  return (
    <Layout>
      <ServicePageTemplate
        services={services}
        title={frontmatter.title}
        heading={frontmatter.heading}
      />
    </Layout>
  )
}

export default ServicePage

export const pageQuery = graphql`
  query Services {
    markdownRemark(frontmatter: { templateKey: { eq: "service-page" } }) {
      frontmatter {
        title
        heading
      }
    }

    services: allMarkdownRemark(
      filter: { frontmatter: { templateKey: { eq: "service" } } }
    ) {
      edges {
        node {
          html
          fields {
            slug
          }
          frontmatter {
            category
            description
            featured
            indexed
            label
            title
            tagline
          }
        }
      }
    }
  }
`
